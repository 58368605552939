<template lang="pug">
.main-wrapper.caixas-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "caixas" }'> Caixas</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } conta`")
                        .p-grid.p-fluid.p-align-end(style='margin: 0 auto; max-width: 500px;')
                            .p-col-12
                                label.form-label Conta Contábil:
                                Dropdown(v-model='model.cd_pai' :options='options.cd_pai' :disabled='Boolean(model.id)'
                                    dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione')
                                
                            .p-col-6
                                label.form-label Código:
                                ProgressBar(v-if='waitingCodigo' mode="indeterminate")
                                InputText(v-else v-model='codigo' disabled)
                                
                            .p-col-6
                                label.form-label Código Resumido:
                                InputText(type='text' v-model='model.ds_codigo_resumido')
                                
                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_descricao.$error }")
                                label.form-label Descrição:
                                InputText(type='text' v-model='$v.model.ds_descricao.$model' )
                                .feedback--errors(v-if='submitted && $v.model.ds_descricao.$error')
                                    .form-message--error(v-if="!$v.model.ds_descricao.maxLength") <b>Nome do Caixa</b> deve ter no máximo 30 caracteres.
                                    .form-message--error(v-if="!$v.model.ds_descricao.minLength") <b>Nome do Caixa</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.ds_descricao.required") <b>Nome do Caixa</b> é obrigatório.
                            
                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ie_tipo.$error }")
                                label.form-label Tipo
                                ProgressBar(v-if='waitingCodigo' mode="indeterminate")
                                SelectButton(v-else v-model='$v.model.ie_tipo.$model' :disabled='Boolean(model.id)' :options='options.ie_tipo' optionLabel='label' optionValue='value' dataKey='value')
                                .feedback--errors(v-if='submitted && $v.model.ie_tipo.$error')
                                    .form-message--error(v-if="!$v.model.ie_tipo.required") <b>Nome do Caixa</b> é obrigatório.
                            
                            .p-col-12
                                label.form-label Natureza
                                ProgressBar(v-if='waitingCodigo' mode="indeterminate")
                                SelectButton(v-else v-model='model.ie_natureza' :disabled='Boolean(model.id)' :options='options.ie_natureza' optionLabel='label' optionValue='value' dataKey='value')
                            
                            .p-col-12.mt-2.footer-infos(v-if="model.id")
                                .p-grid
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12.mt-2
                                Button(label='Salvar' type="submit")

                
</template>

<style lang="scss">
    .caixas-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #CACACA;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-dropdown.p-disabled .p-dropdown-trigger,
        .p-dropdown.p-disabled .p-dropdown-label {
            background-color: #CACACA;
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
        .footer-infos { 
            font-size: 12px;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'

    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import { PlanoContas, DominioValor} from './../../middleware'
    import { required, minLength, maxLength } from 'vuelidate/lib/validators'
    import CustomMultiSelect from '../CustomComponents/CustomMultiSelect.vue'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password,
                InputMask, SelectButton, Dropdown, Tooltip, CustomMultiSelect
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true
            this.waitingNatureza = true
            DominioValor.findAll({ds_mnemonico: ['TIPO_NATUREZA', 'TIPO_PLANO_CONTAS']}).then(response => {
                if (response.status == 200){
                    response.data['TIPO_NATUREZA'].valores.forEach((un) => {
                        this.options.ie_natureza.push({ value: un.ie_valor, label: un.ds_valor})
                    });
                    response.data['TIPO_PLANO_CONTAS'].valores.forEach((un) => {
                        if(un.ie_valor != 'F')
                            this.options.ie_tipo.push({ value: un.ie_valor, label: un.ds_valor})
                    });
                        
                    this.waitingNatureza = false
                }  
            })

            PlanoContas.findAll().then(response => {
                if (response.status == 200) {
                    response.data.forEach(item => {
                        this.options.cd_pai.push({
                            text: item.ds_descricao,
                            value: item.id
                        })
                    })
                }
            })
            
            // buscar caixa especifico (fluxo de edicao)
            let id = 0
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            if (id != 0) {
                PlanoContas.find(id).then(response => {
                    if (response.status == 200) {
                        console.log("RESPOSTA", response.data)
                        this.model = response.data
                        this.codigo = response.data.ds_codigo
                    }
                    this.waiting = false
                })
            } else {
                this.waitingCodigo = true
                PlanoContas.gerarCodigo({}).then(response => {
                    if(response.status == 200) {
                        console.log(response)
                        this.codigo = response.data
                        this.waitingCodigo = false
                    }
                })
                this.waiting = false
            }
        },
        data () {
            return {
                model: {
                    ds_descricao: '',
                    cd_pai: null,
                    ie_tipo: null,
                    ie_natureza: null,
                    ds_codigo_resumido: '',
                 
                },
                options: {
                    cd_pai: [],
                    ie_tipo: [],
                    ie_natureza: [],
                },
               
                control: {
                    ieDisablePosto: true,
                    isDisableUsuario: true,
                    isDisableValorInicial: true,
                },
                codigo: '',
                waiting: true,
                waitingCodigo: false,
                waitingNatureza: false,
                waitingForm: false,
                submitted: false,
            }
        },
        validations () {
            let v = {
                model: {
                    ds_descricao: { required, minLength: minLength(2), maxLength: maxLength(30) },
                    ie_tipo: {required}
                }
            }
            
            return v
        },
        watch: {
            "model.cd_pai": function(value) {
                this.waitingCodigo = true
                PlanoContas.gerarCodigo({cd_pai: value}).then(response => {
                    if(response.status == 200) {
                        console.log(response)
                        this.codigo = response.data
                        this.waitingCodigo = false
                    }
                })
            },
        },
        methods: {
            findSalaById(id) {
                return this.options.salas.find(item => {
                    return item.value == id
                })?.label || ''
            },
            handleSubmit () {
                this.submitted = true
                
                this.$v.$touch()
                if (this.$v.$invalid) return 0
                let dataSend = Object.assign({}, this.model)
                this.waitingForm = true
                
                PlanoContas.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'plano-de-contas' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.detail)
                            response.data.detail.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false
                })
            }
         }
      }
</script>